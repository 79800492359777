<template>
  <base-layout-two
    page-title="Billing"
    :page-default-back-link="`/users/${$route.params.id}`"
    content-class="backround-white"
  >
    <section class="ion-padding">
      <h1>💳 Orders</h1>

      <ion-button class="regular-button" expand="block" :router-link="`/users/${$route.params.id}/folios/create`">New Order</ion-button>
    </section>

    <section class="ion-padding">
      <ion-list>
        <folio-list-item
          v-for="folio in folios"
          :key="folio.id"
          :folio="folio"
          :router-link="`/users/${$route.params.id}/folios/${folio.id}`"
        >
        </folio-list-item>
      </ion-list>
    </section>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import FolioListItem from "@/components/users/folios/FolioListItem.vue";

export default {
  components: {
    IonList,
    IonButton,
    FolioListItem,
  },
  data() {
    return {
      folios: [],
    };
  },
  ionViewWillEnter() {
    this.fetchFolios();
  },
  methods: {
    async fetchFolios() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/folios?user_id=${this.$route.params.id}`
        )
        .then((response) => {
          this.folios = response.data.folios;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>