<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-label>
      <div class="h6">
        {{ title }}
      </div>
      <h6>Total: ${{ Math.abs(total.amount).toFixed(2) }}</h6>
    </ion-label>

    <!-- Right Side -->
    <ion-label slot="end" class="ion-text-right ion-no-margin">
      <ion-chip v-if="balance < 0" color="danger">
        <ion-label class="ion-text-capitalize">Requires Payment</ion-label>
      </ion-chip>

      <h5>Balance: ${{ Math.abs(balance).toFixed(2) }}</h5>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonChip } from "@ionic/vue";

export default {
  props: {
    folio: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },
  components: {
    IonItem,
    IonLabel,
    IonChip,
  },
  computed: {
    title() {
      if (this.folio.reservation) {
        return `Reservation (${this.folio.reservation.confirmation})`;
      } else return `Order (${this.folio.confirmation})`;
    },

    line_items() {
      let line_items = this.folio.transactions.filter(
        (x) => x.type === "line_item"
      );

      return line_items;
    },

    payments() {
      let payments = this.folio.transactions.filter((x) => {
        if (
          x.type === "charge" &&
          x.stripe_payment_intent_status === "succeeded"
        )
          return true;
        return false;
      });

      return payments;
    },

    subtotal() {
      let subtotal = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_final,
        0
      );

      let transaction = {
        description: "Subtotal",
        amount: Number(subtotal),
      };

      return transaction;
    },

    taxesAndFees() {
      let taxesAndFees = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_taxes,
        0
      );

      let transaction = {
        description: "Taxes & Fees",
        amount: Number(taxesAndFees),
      };

      return transaction;
    },

    total() {
      let total = this.line_items.reduce(
        (partial_sum, a) => partial_sum + a.price_total,
        0
      );

      let transaction = {
        description: "Total",
        amount: Number(total),
      };

      return transaction;
    },

    balance() {
      let paymentsTotal = this.payments.reduce(
        (partial_sum, a) => partial_sum + (a.stripe_charge_amount/100 - a.stripe_refund_amount/100),
        0
      );

      return Number(paymentsTotal + this.total.amount).toFixed(2);
    },
  },
};
</script>
<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>